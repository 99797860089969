import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './../styles/DropdownMenu.css'; // Crée ce fichier CSS pour le style

function DropdownMenu() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="dropdown">
      <button onClick={toggleDropdown} className="dropdown-button">
        Menu
      </button>
      {isOpen && (
        <div className="dropdown-content">
          <Link to="/">Home</Link>
          <Link to="/about">About</Link>
        </div>
      )}
    </div>
  );
}

export default DropdownMenu;